import React from 'react';
import clsx from 'clsx';

interface Props {
  rel?: string;
  target?: string;
  href?: string;
}

export default function CenteredButton(props: React.PropsWithChildren<Props>): JSX.Element {
  return (
    <div className="flex justify-around mb-4 mt-2">
      <a
        {...props}
        className={clsx(
          'bg-transparent',
          'hover:bg-pink-500',
          'text-pink-700',
          'font-medium',
          'hover:text-white border',
          'border-pink-500',
          'hover:border-transparent',
          'py-2',
          'px-4',
          'rounded',
          'focus:outline-none'
        )}
      ></a>
    </div>
  );
}
