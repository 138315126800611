import React from 'react';
import BasicLayout from 'src/components/layouts/BasicLayout';
import SEO from 'src/components/SEO';
import { Paragraph, H3, A } from 'src/DesignSystem';
import neonSunLogo from 'src/images/neon-sun-logo.png';
import NeonSunPdf from 'src/documents/NeonSunMk5.pdf';
import Tag from 'src/components/Tag';
import {
  faCodeBranch,
  faUser,
  faClock,
} from '@fortawesome/pro-solid-svg-icons';
import NeonSunCharacter from 'src/components/NeonSunCharacter';
import CenteredButton from 'src/components/CenteredButton';

const PAGE_DESCRIPTION =
  'A bit of cyber and a bit of punk. An RPG for 5 underworld scoundrels and a GM.';

export default function NeonSun(): JSX.Element {
  return (
    <BasicLayout>
      <SEO title="Neon Sun" description={PAGE_DESCRIPTION} />
      <div className="max-w-4xl p-4 mx-auto text-gray-900 mb-8">
        <img className="mx-auto mb-4" src={neonSunLogo} alt="Neon Sun Logo" />
        <Tag color="orange" icon={faClock}>
          1-2 sessions
        </Tag>
        <Tag color="purple" icon={faUser}>
          5 + GM
        </Tag>
        <Tag color="teal" icon={faCodeBranch}>
          Mk. 5
        </Tag>
        <CenteredButton href={NeonSunPdf} rel="noopenener" target="blank">
          Download PDF
        </CenteredButton>
        <Paragraph>
          Neon Sun has created <em>The Fountain of Youth</em>. Rumors disagree
          about precisely what this invention is, what it does, and how it
          works, but what’s clear is that it’s well protected, and everyone
          wants it. So Yi Shin is going to steal it. He’s hired a crew of
          unlikely allies to pull off a heist that the world believes is
          impossible.
        </Paragraph>
        <Paragraph>
          HOWEVER — In an operation to steal access codes to the heart of Neon
          Sun’s headquarters, Jezebel Lyon was captured by Ruriko agents in a
          robotic warehouse.
        </Paragraph>
        <Paragraph>
          EVEN NOW she is being interrogated as the rest of the crew tries to
          find a way to free her. But it’s only a matter of time until Ruriko
          agent Miyoshi Takuro discovers why she was after the access codes, and
          things get much more complicated.
        </Paragraph>
        <Paragraph className="italic">
          How will the crew free Jezebel from her capture and claim the access
          codes to Neon Sun’s inmost sanctum?
        </Paragraph>
        <Paragraph className="italic">
          What other dangers lie in their path?
        </Paragraph>
        <Paragraph className="italic">
          Will they be able to steal the Fountain of Youth? If they do, can they
          escape with their lives? By the time they escape, will they have
          decided what to do with this prize?
        </Paragraph>
        <H3>The Game</H3>
        <Paragraph>
          Neon Sun uses the &quot;Tales from the Wild Blue Yonder&quot; system, pioneered
          by John Harper in{' '}
          <A href="http://www.onesevendesign.com/ladyblackbird/">
            Lady Blackbird
          </A>
          . The system is designed for short adventures of pre-generated
          characters full of interesting conflicts, relationships, and ways of
          achieving or discarding their goals.
        </Paragraph>
        <Paragraph>
          Neon Sun is a <strong>cyperpunk heist</strong> game designed to be
          played out over 1-2 sessions. During the course of the heist, the
          characters will need to decide what they value, who they care about,
          and how a disparate group of scoundrals with varying motives handle
          the singular prize. Get ready for the greatest theft the Seattle
          underworld has ever seen.
        </Paragraph>
        <H3>The Characters</H3>
        <Paragraph>
          A strange group of people, to be sure, but they&apos;re all here for a
          reason.
        </Paragraph>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
          <NeonSunCharacter
            name="Yi Shin"
            description="Underworld kingpin, street samurai, and mastermind of the heist."
            traits={['Ronin', 'Kingpin', 'Street Samurai', 'Cypernetic']}
          />
          <NeonSunCharacter
            name="Jezebel Lyon, PhD"
            description="A cruel, ruthless mage moving up in the underworld."
            traits={['Mage Mk. 14', 'Scoundrel', 'Cautious', 'Scholar']}
          />
          <NeonSunCharacter
            name="Dr. Jordan Rose"
            description="A charming vetinarian and minor warlock who ended up on the wrong side of the law."
            traits={[
              'Veterinarian',
              'Gentleman',
              'Digital Nerves',
              'Warlock Mk. 4',
            ]}
          />
          <NeonSunCharacter
            name="_hornet"
            subtitle="[Emily Strix]"
            description="A technomancer and socialite who has plans for this world."
            traits={['Hacker', 'Grease Monkey', 'Debutante', 'Bold']}
          />
          <NeonSunCharacter
            name="Kona Hailama"
            description="The best wheelman, best shot, and worst therapist in Seattle are all the same person."
            traits={['Gun Nut', 'Wheelman', 'Smuggler', 'Therapist']}
          />
        </div>
        <H3>Feedback</H3>
        <Paragraph>
          Have thoughts? A cool story of the heist? Directions to the Neon Sun
          Fountain of Youth so that I can live forever? Email me at{' '}
          <A href="mailto:sam@samvbeckmann.com">sam@samvbeckmann.com</A>.
        </Paragraph>
      </div>
    </BasicLayout>
  );
}
