import React from 'react';

interface Props {
  name: string;
  subtitle?: string;
  description: string;
  traits: string[];
}

export default function NeonSunCharacter(props: Props): JSX.Element {
  return (
    <div className="border-2 border-black">
      <div className="bg-black p-2 text-center text-white font-medium">
        {props.name}
        {props.subtitle && <div className="text-sm">{props.subtitle}</div>}
      </div>
      <div className="p-2 text-sm">
        {props.description}
        <div className="grid grid-cols-2 md:grid-cols-1">
          {props.traits.map((trait, index) => (
            <div
              key={index}
              className="bg-black text-white font-medium py-1 px-2 mt-1 mr-1 md:mr-0"
            >
              {trait}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
